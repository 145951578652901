import React from 'react';
import Head from 'next/head';
import dynamic from 'next/dynamic';

const HeroSection = dynamic(() => import('@/components/sections/HeroSection'));
const ProfileCard = dynamic(() => import('@/components/sections/ProfileCard'));
const CallToAction = dynamic(() => import('@/components/sections/CallToAction'));
const Services = dynamic(() => import('@/components/sections/Services'));
const Testimonials = dynamic(() => import('@/components/sections/Testimonials'));
const Faq = dynamic(() => import('@/components/sections/Faq'));

const HomePage = () => (
  <>
    <Head>
      <title>Best Psychologist Calgary | Registered Psychologist & Therapy Services</title>
      <meta
        name="description"
        content="Expert Relationship Counselling Calgary & Family Therapy Services."
      />
      <link rel="preload" href="/hero-bg.webp" as="image" />
    </Head>

    <HeroSection />

    <div className="max-w-7xl mx-auto px-4 pt-8 space-y-24">
      <div className="grid md:grid-cols-2 gap-8">
        <ProfileCard />
        <CallToAction />
      </div>
      
      <Services />
      <Testimonials />
      <Faq />
    </div>
  </>
);

export default HomePage;
